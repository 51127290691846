import { useState, useEffect } from "react";
import Slide1 from "../assets/hero_assets/Slide-1.jpeg";
import Slide2 from "../assets/hero_assets/Slide-2.jpg";
import Slide3 from "../assets/hero_assets/Slide-3.jpg";
import Slide4 from "../assets/hero_assets/Slide-4.jpg";
import Slide5 from "../assets/hero_assets/Slide-5.jpg";
import Slide6 from "../assets/hero_assets/Slide-6.jpg";
import { useMediaQuery } from "react-responsive";

const slides = [
  {
    title: "Slide-1",
    imageUrl: Slide1,
  },
  {
    title: "Slide-2",
    imageUrl: Slide2,
  },
  {
    title: "Slide-3",
    imageUrl: Slide6,
  },
  {
    title: "Slide-4",
    imageUrl: Slide5,
  },
  {
    title: "Slide-5",
    imageUrl: Slide3,
  },
  {
    title: "Slide-6",
    imageUrl: Slide4,
  },
];

const LaptopOrDesktop = ({ children }) => {
  const isLaptopOrDesktop = useMediaQuery({ minWidth: 1080, maxWidth: 1980 });
  return isLaptopOrDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 766, maxWidth: 1080 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 766 });
  return isMobile ? children : null;
};

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000); // ganti slide setiap 3 detik

    return () => clearInterval(slideInterval);
  }, []);

  return (
    <>
      <LaptopOrDesktop>
        <div className="relative w-full h-[480px] overflow-hidden">
          <div
            className="flex transition-transform duration-1000"
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            {slides.map((slide, index) => (
              <div key={index} className="w-full flex-shrink-0 min-w-full">
                <img
                  src={slide.imageUrl}
                  alt={slide.title}
                  className="w-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </LaptopOrDesktop>

      <Tablet>
        <div className="relative w-full overflow-hidden pt-3">
          <div
            className="flex transition-transform duration-1000"
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            {slides.map((slide, index) => (
              <div key={index} className="w-full flex-shrink-0 min-w-full">
                <img
                  src={slide.imageUrl}
                  alt={slide.title}
                  className="w-full object-cover h-[300px]"
                />
              </div>
            ))}
          </div>
        </div>
      </Tablet>

      <Mobile>
        <div className="relative w-full overflow-hidden">
          <div
            className="flex transition-transform duration-1000"
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            {slides.map((slide, index) => (
              <div key={index} className="w-full flex-shrink-0 min-w-full">
                <img
                  src={slide.imageUrl}
                  alt={slide.title}
                  className="w-full object-cover h-[200px]"
                />
              </div>
            ))}
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Hero;
