import Container from "./Container";
import Team01 from "../assets/teams_assets/team01.png";
import Team02 from "../assets/teams_assets/team02.png";
import Team03 from "../assets/teams_assets/team03.png";
import { useState } from "react";
import MobileContainer from "./MobileContainer";
import { useMediaQuery } from "react-responsive";
import Animation from "./Animation";
import TabletContainer from "./TabletContainer";

export default function OurTeams() {
  const [team01IsOpen, setIsTeam01Open] = useState(false);

  const toggleTeam01 = () => {
    setIsTeam01Open(!team01IsOpen);
  };

  const [team02IsOpen, setIsTeam02Open] = useState(false);

  const toggleTeam02 = () => {
    setIsTeam02Open(!team02IsOpen);
  };

  // const [team03IsOpen, setIsTeam03Open] = useState(false);

  // const toggleTeam03 = () => {
  //   setIsTeam03Open(!team03IsOpen);
  // };

  const LaptopOrDesktop = ({ children }) => {
    const isLaptopOrDesktop = useMediaQuery({ minWidth: 1080, maxWidth: 1980 });
    return isLaptopOrDesktop ? children : null;
  };

  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 766, maxWidth: 1080 });
    return isTablet ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 766 });
    return isMobile ? children : null;
  };

  return (
    <>
      {team01IsOpen && (
        <>
          <LaptopOrDesktop>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[600px]">
                <h2 className="text-xl font-semibold">
                  Rahmad Adam, S.E., M.Ak., Ak., CA, CPA, BKP
                </h2>
                <h2 className=" text-red-600 font-semibold mb-4">
                  AS a Managing Partner
                </h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    Adam, also known as Rahmad Adam, is RA Consulting Services'
                    managing partner. Adam will be a great asset to our
                    engagement team, bringing over 25 years of tax consulting
                    experience. He is well-versed in the risks and concerns
                    associated with domestic and international taxation. In tax
                    appeal proceedings before the Indonesian Tax Court, he has
                    also represented clients with great experience.
                  </p>
                  <p className=" pt-4 indent-8 text-justify">
                    His expertise includes extensive knowledge of tax due
                    diligence, audits, diagnostic reviews, cross-border
                    transactions, and tax dispute settlement. Adam has worked
                    with global corporations in various industries, such as
                    manufacturing and consumer goods. He has a wide range of
                    expertise in strategic planning for international
                    corporations. He offers clients the best options and
                    solutions to reduce any possible tax exposures.
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsTeam01Open(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </LaptopOrDesktop>

          <Tablet>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[600px]">
                <h2 className="text-xl font-semibold">
                  Rahmad Adam, S.E., M.Ak., Ak., CA, CPA, BKP
                </h2>
                <h2 className=" text-red-600 font-semibold mb-4">
                  AS a Managing Partner
                </h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    Adam, also known as Rahmad Adam, is RA Consulting Services'
                    managing partner. Adam will be a great asset to our
                    engagement team, bringing over 25 years of tax consulting
                    experience. He is well-versed in the risks and concerns
                    associated with domestic and international taxation. In tax
                    appeal proceedings before the Indonesian Tax Court, he has
                    also represented clients with great experience.
                  </p>
                  <p className=" pt-4 indent-8 text-justify">
                    His expertise includes extensive knowledge of tax due
                    diligence, audits, diagnostic reviews, cross-border
                    transactions, and tax dispute settlement. Adam has worked
                    with global corporations in various industries, such as
                    manufacturing and consumer goods. He has a wide range of
                    expertise in strategic planning for international
                    corporations. He offers clients the best options and
                    solutions to reduce any possible tax exposures.
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsTeam01Open(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Tablet>

          <Mobile>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <MobileContainer>
                <div className="bg-white rounded-lg p-6">
                  <h2 className="text-xl font-semibold">
                    Rahmad Adam, S.E., M.Ak., Ak., CA, CPA, BKP
                  </h2>
                  <h2 className=" text-red-600 font-semibold mb-4">
                    AS a Managing Partner
                  </h2>
                  <div className="mb-4">
                    <p className=" indent-8 text-justify">
                      Adam, also known as Rahmad Adam, is RA Consulting
                      Services' managing partner. Adam will be a great asset to
                      our engagement team, bringing over 25 years of tax
                      consulting experience. He is well-versed in the risks and
                      concerns associated with domestic and international
                      taxation. In tax appeal proceedings before the Indonesian
                      Tax Court, he has also represented clients with great
                      experience.
                    </p>
                    <p className=" pt-4 indent-8 text-justify">
                      His expertise includes extensive knowledge of tax due
                      diligence, audits, diagnostic reviews, cross-border
                      transactions, and tax dispute settlement. Adam has worked
                      with global corporations in various industries, such as
                      manufacturing and consumer goods. He has a wide range of
                      expertise in strategic planning for international
                      corporations. He offers clients the best options and
                      solutions to reduce any possible tax exposures.
                    </p>
                  </div>
                  <button
                    className=" bg-red-600 text-white px-4 py-2 rounded"
                    onClick={() => setIsTeam01Open(false)}
                  >
                    Close
                  </button>
                </div>
              </MobileContainer>
            </div>
          </Mobile>
        </>
      )}

      {team02IsOpen && (
        <>
          <LaptopOrDesktop>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[600px]">
                <h2 className="text-xl font-semibold">
                  Rheza Siswa Wiguna, S.Sos., BKP
                </h2>
                <h2 className=" text-red-600 font-semibold mb-4">
                  AS a Tax Partner
                </h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    Rheza has over 15 years of experience in the local tax
                    consulting firms and held some corporates roles in
                    Indonesia. Throughout her professional career, Rheza has
                    been dedicated in providing highest level of services to her
                    clients consisting of multinational and domestic companies
                    from a broad range of industries such as services,
                    automotive, manufacturing, food and beverages, hospitality,
                    SMEs, and mining.
                  </p>
                  <p className=" pt-4 indent-8 text-justify">
                    Rheza's expertise areas range from tax due diligence, tax
                    advisory, tax diagnostic review, tax dispute, to tax
                    compliance and other tax matters. Rheza is a registered tax
                    practitioner level C, tax and a member of the Indonesian Tax
                    Consultants Association (IKPI).
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsTeam02Open(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </LaptopOrDesktop>

          <Tablet>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[600px]">
                <h2 className="text-xl font-semibold">
                  Rheza Siswa Wiguna, S.Sos., BKP
                </h2>
                <h2 className=" text-red-600 font-semibold mb-4">
                  AS a Tax Partner
                </h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    Rheza has over 15 years of experience in the local tax
                    consulting firms and held some corporates roles in
                    Indonesia. Throughout her professional career, Rheza has
                    been dedicated in providing highest level of services to her
                    clients consisting of multinational and domestic companies
                    from a broad range of industries such as services,
                    automotive, manufacturing, food and beverages, hospitality,
                    SMEs, and mining.
                  </p>
                  <p className=" pt-4 indent-8 text-justify">
                    Rheza's expertise areas range from tax due diligence, tax
                    advisory, tax diagnostic review, tax dispute, to tax
                    compliance and other tax matters. Rheza is a registered tax
                    practitioner level C, tax and a member of the Indonesian Tax
                    Consultants Association (IKPI).
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsTeam02Open(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Tablet>

          <Mobile>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <MobileContainer>
                <div className="bg-white rounded-lg p-6">
                  <h2 className="text-xl font-semibold">
                    Rheza Siswa Wiguna, S.Sos., BKP
                  </h2>
                  <h2 className=" text-red-600 font-semibold mb-4">
                    AS a Tax Partner
                  </h2>
                  <div className="mb-4">
                    <p className=" indent-8 text-justify">
                      Rheza has over 15 years of experience in the local tax
                      consulting firms and held some corporates roles in
                      Indonesia. Throughout her professional career, Rheza has
                      been dedicated in providing highest level of services to
                      her clients consisting of multinational and domestic
                      companies from a broad range of industries such as
                      services, automotive, manufacturing, food and beverages,
                      hospitality, SMEs, and mining.
                    </p>
                    <p className=" pt-4 indent-8 text-justify">
                      Rheza's expertise areas range from tax due diligence, tax
                      advisory, tax diagnostic review, tax dispute, to tax
                      compliance and other tax matters. Rheza is a registered
                      tax practitioner level C, tax and a member of the
                      Indonesian Tax Consultants Association (IKPI).
                    </p>
                  </div>
                  <button
                    className=" bg-red-600 text-white px-4 py-2 rounded"
                    onClick={() => setIsTeam02Open(false)}
                  >
                    Close
                  </button>
                </div>
              </MobileContainer>
            </div>
          </Mobile>
        </>
      )}

      {/* {team03IsOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-[600px]">
            <h2 className="text-xl font-semibold">
              Rheza Siswa Wiguna, S.Sos., BKP
            </h2>
            <h2 className=" text-red-600 font-semibold mb-4">
              AS a Tax Partner
            </h2>
            <div className="mb-4">
              <p className=" indent-8 text-justify">
                Rheza has over 15 years of experience in the local tax
                consulting firms and held some corporates roles in Indonesia.
                Throughout her professional career, Rheza has been dedicated in
                providing highest level of services to her clients consisting of
                multinational and domestic companies from a broad range of
                industries such as services, automotive, manufacturing, food and
                beverages, hospitality, SMEs, and mining.
              </p>
              <p className=" pt-4 indent-8 text-justify">
                Rheza's expertise areas range from tax due diligence, tax
                advisory, tax diagnostic review, tax dispute, to tax compliance
                and other tax matters. Rheza is a registered tax practitioner
                level C, tax and a member of the Indonesian Tax Consultants
                Association (IKPI).
              </p>
            </div>
            <button
              className=" bg-red-600 text-white px-4 py-2 rounded"
              onClick={() => setIsTeam03Open(false)}
            >
              Close
            </button>
          </div>
        </div>
      )} */}
      <LaptopOrDesktop>
        <Animation>
          <Container>
            <div>
              <div>
                <h2 className=" font-bold text-[25px] italic">Our Teams</h2>
                <div className=" bg-red-600 h-1 w-20 rounded-full"></div>
              </div>
              <div className=" mt-6 flex gap-16">
                <div>
                  <div className=" flex justify-center">
                    <button onClick={toggleTeam01}>
                      <img
                        className=" bg-slate-100 w-[200px] rounded-md"
                        src={Team01}
                        alt=""
                      />
                    </button>
                  </div>
                  <h3 className=" w-[250px] font-semibold pt-2 text-center">
                    Rahmad Adam, S.E., M.Ak., Ak., CA, CPA, BKP
                  </h3>
                  <p className=" text-center text-red-600 font-bold pt-1">
                    Managing Partner
                  </p>
                </div>
                <div className=" ">
                  <div className=" flex justify-center">
                    <button onClick={toggleTeam02}>
                      <img
                        className=" bg-slate-100 w-[200px] rounded-md"
                        src={Team02}
                        alt=""
                      />
                    </button>
                  </div>
                  <h3 className=" w-[250px] font-semibold pt-2 text-center">
                    Rheza Siswa Wiguna, S.Sos., BKP
                  </h3>
                  <p className=" text-center text-red-600 font-bold pt-1">
                    Tax Partner
                  </p>
                </div>
                <div className=" ">
                  <div className=" flex justify-center">
                    <button>
                      <img
                        className=" bg-slate-100 w-[200px] rounded-md"
                        src={Team03}
                        alt=""
                      />
                    </button>
                  </div>
                  <h3 className=" w-[250px] font-semibold pt-2 text-center">
                    Tami Nisita Rahmani, S.E., BKP
                  </h3>
                  <p className=" text-center text-red-600 font-bold pt-1">
                    Assistant Tax Manager
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </Animation>
      </LaptopOrDesktop>

      <Tablet>
        <Animation>
          <TabletContainer>
            <div>
              <div>
                <h2 className=" font-bold text-[25px] italic">Our Teams</h2>
                <div className=" bg-red-600 h-1 w-20 rounded-full"></div>
              </div>
              <div className=" mt-6 flex justify-center gap-16">
                <div>
                  <div className=" flex justify-center">
                    <button onClick={toggleTeam01}>
                      <img
                        className=" bg-slate-100 w-[200px] rounded-md"
                        src={Team01}
                        alt=""
                      />
                    </button>
                  </div>
                  <h3 className=" w-[250px] font-semibold pt-2 text-center">
                    Rahmad Adam, S.E., M.Ak., Ak., CA, CPA, BKP
                  </h3>
                  <p className=" text-center text-red-600 font-bold pt-1">
                    Managing Partner
                  </p>
                </div>
                <div className=" ">
                  <div className=" flex justify-center">
                    <button onClick={toggleTeam02}>
                      <img
                        className=" bg-slate-100 w-[200px] rounded-md"
                        src={Team02}
                        alt=""
                      />
                    </button>
                  </div>
                  <h3 className=" w-[250px] font-semibold pt-2 text-center">
                    Rheza Siswa Wiguna, S.Sos., BKP
                  </h3>
                  <p className=" text-center text-red-600 font-bold pt-1">
                    Tax Partner
                  </p>
                </div>
              </div>
              <div className=" flex justify-center mt-6">
                <div className=" ">
                  <div className=" flex justify-center">
                    <button>
                      <img
                        className=" bg-slate-100 w-[200px] rounded-md"
                        src={Team03}
                        alt=""
                      />
                    </button>
                  </div>
                  <h3 className=" w-[250px] font-semibold pt-2 text-center">
                    Tami Nisita Rahmani, S.E., BKP
                  </h3>
                  <p className=" text-center text-red-600 font-bold pt-1">
                    Assistant Tax Manager
                  </p>
                </div>
              </div>
            </div>
          </TabletContainer>
        </Animation>
      </Tablet>

      <Mobile>
        <Animation>
          <MobileContainer>
            <div>
              <div>
                <h2 className=" font-bold text-[25px] italic">Our Teams</h2>
                <div className=" bg-red-600 h-1 w-20 rounded-full"></div>
              </div>
              <div className=" mt-6">
                <div>
                  <div className=" flex justify-center">
                    <button onClick={toggleTeam01}>
                      <img
                        className=" bg-slate-100 w-[200px] rounded-md"
                        src={Team01}
                        alt=""
                      />
                    </button>
                  </div>
                  <h3 className=" font-semibold pt-2 text-center">
                    Rahmad Adam, S.E., M.Ak., Ak., CA, CPA, BKP
                  </h3>
                  <p className=" text-center text-red-600 font-bold pt-1">
                    Managing Partner
                  </p>
                </div>
                <div className=" py-5">
                  <div className=" flex justify-center">
                    <button onClick={toggleTeam02}>
                      <img
                        className=" bg-slate-100 w-[200px] rounded-md"
                        src={Team02}
                        alt=""
                      />
                    </button>
                  </div>
                  <h3 className=" font-semibold pt-2 text-center">
                    Rheza Siswa Wiguna, S.Sos., BKP
                  </h3>
                  <p className=" text-center text-red-600 font-bold pt-1">
                    Tax Partner
                  </p>
                </div>
                <div className=" ">
                  <div className=" flex justify-center">
                    <button>
                      <img
                        className=" bg-slate-100 w-[200px] rounded-md"
                        src={Team03}
                        alt=""
                      />
                    </button>
                  </div>
                  <h3 className=" font-semibold pt-2 text-center">
                    Tami Nisita Rahmani, S.E., BKP
                  </h3>
                  <p className=" text-center text-red-600 font-bold pt-1">
                    Assistant Tax Manager
                  </p>
                </div>
              </div>
            </div>
          </MobileContainer>
        </Animation>
      </Mobile>
    </>
  );
}
