import React from "react";
import { useInView } from "react-intersection-observer";

export default function Animation({ children }) {
  const { ref, inView } = useInView({
    triggerOnce: false, // hanya sekali animasi muncul
    threshold: 0.4, // animasi muncul saat 10% elemen terlihat
  });
  return (
    <div
      ref={ref}
      className={`transition-opacity duration-3000 transform ${
        inView ? "opacity-100 animate-fade-in-right" : "opacity-0 translate-x-5"
      }`}
    >
      {children}
    </div>
  );
}
