import Container from "./Container";
import NalaExplorer from "../assets/clients_assets/nala.png";
import Knu from "../assets/clients_assets/Knu.jpg";
import LdsGroup from "../assets/clients_assets/lds-group-black.png";
import Minori from "../assets/clients_assets/logo-minori-300x136.webp";
import Polyplex from "../assets/clients_assets/polyplex-logo.jpg";
import AnakSehat from "../assets/clients_assets/pt-anak-sehat-idaman-hati.png";
import Cataler from "../assets/clients_assets/pt-cataler-indonesia.png";
import Hybrid from "../assets/clients_assets/pt-hybrid-power.png";
import Hyundai from "../assets/clients_assets/pt-hyundai-elevator-indonesia.jpeg";
import Nikawa from "../assets/clients_assets/pt-nikawa-textile-industry.png";
import Penilai from "../assets/clients_assets/pt-penilai-harga-efek-indonesia-(PHEI).jpeg";
import Sumbawa from "../assets/clients_assets/pt-sumbawa-timur.jpeg";
import Vale from "../assets/clients_assets/pt-vale-indonesia.png";
import Mastrada from "../assets/clients_assets/tokopedia-pt-mastrada.png";
import { useMediaQuery } from "react-responsive";
import MobileContainer from "./MobileContainer";
import Animation from "./Animation";
import TabletContainer from "./TabletContainer";

export default function OurClients() {
  const LaptopOrDesktop = ({ children }) => {
    const isLaptopOrDesktop = useMediaQuery({ minWidth: 1080, maxWidth: 1980 });
    return isLaptopOrDesktop ? children : null;
  };

  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 766, maxWidth: 1080 });
    return isTablet ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 766 });
    return isMobile ? children : null;
  };

  return (
    <>
      <LaptopOrDesktop>
        <Animation>
          <Container>
            <div>
              <div>
                <h2 className=" font-bold text-[25px] italic">Our Clients</h2>
                <div className=" bg-red-600 h-1 w-24 rounded-full"></div>
              </div>
              <div className="my-7 grid grid-cols-5">
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={NalaExplorer} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={LdsGroup} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Minori} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={AnakSehat} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Cataler} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Nikawa} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Hybrid} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Hyundai} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Polyplex} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Penilai} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Sumbawa} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Vale} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Mastrada} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Knu} alt="" />
                </div>
              </div>
            </div>
          </Container>
        </Animation>
      </LaptopOrDesktop>

      <Tablet>
        <Animation>
          <TabletContainer>
            <div>
              <div>
                <h2 className=" font-bold text-[25px] italic">Our Clients</h2>
                <div className=" bg-red-600 h-1 w-24 rounded-full"></div>
              </div>
              <div className="my-7 grid grid-cols-3 gap-y-5">
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={NalaExplorer} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={LdsGroup} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Minori} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={AnakSehat} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Cataler} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Nikawa} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Hybrid} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Hyundai} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Polyplex} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Penilai} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Sumbawa} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Vale} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Mastrada} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Knu} alt="" />
                </div>
              </div>
            </div>
          </TabletContainer>
        </Animation>
      </Tablet>

      <Mobile>
        <Animation>
          <MobileContainer>
            <div>
              <div>
                <h2 className=" font-bold text-[25px] italic">Our Clients</h2>
                <div className=" bg-red-600 h-1 w-24 rounded-full"></div>
              </div>
              <div className="my-71 grid grid-cols-2 gap-y-6">
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={NalaExplorer} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={LdsGroup} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Minori} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={AnakSehat} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Cataler} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Nikawa} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Hybrid} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Hyundai} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Polyplex} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Penilai} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Sumbawa} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Vale} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Mastrada} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <img className=" w-32" src={Knu} alt="" />
                </div>
              </div>
            </div>
          </MobileContainer>
        </Animation>
      </Mobile>
    </>
  );
}
