import { useState } from "react";
import Container from "./Container";
import { useMediaQuery } from "react-responsive";
import MobileContainer from "./MobileContainer";
import Animation from "./Animation";
import TabletContainer from "./TabletContainer";

export default function OurServices() {
  const [service01, setIsService01] = useState(false);

  const toggleService01 = () => {
    setIsService01(!service01);
  };

  const [service02, setIsService02] = useState(false);

  const toggleService02 = () => {
    setIsService02(!service02);
  };

  const [service03, setIsService03] = useState(false);

  const toggleService03 = () => {
    setIsService03(!service03);
  };

  const [service04, setIsService04] = useState(false);

  const toggleService04 = () => {
    setIsService04(!service04);
  };

  const [service05, setIsService05] = useState(false);

  const toggleService05 = () => {
    setIsService05(!service05);
  };

  const [service06, setIsService06] = useState(false);

  const toggleService06 = () => {
    setIsService06(!service06);
  };

  const LaptopOrDesktop = ({ children }) => {
    const isLaptopOrDesktop = useMediaQuery({ minWidth: 1080, maxWidth: 1980 });
    return isLaptopOrDesktop ? children : null;
  };

  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 766, maxWidth: 1080 });
    return isTablet ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 766 });
    return isMobile ? children : null;
  };

  return (
    <>
      {service01 && (
        <>
          <LaptopOrDesktop>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[600px]">
                <h2 className="text-xl font-semibold mb-4">Tax Consulting</h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    We provide advice based on the applicable tax laws and
                    regulations. Our consultation services are highly flexible
                    and tailored to our clients' needs. Consultations can be
                    conducted on various matters such as tax planning,
                    independent opinions on transactions, application for tax
                    facilities, corporate restructuring, tax audits, tax
                    compliance, and other tax-related issues faced by the
                    company. We prepare advice both verbally and in writing,
                    offering insights and interpretations of the relevant tax
                    regulations.
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsService01(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </LaptopOrDesktop>

          <Tablet>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[600px]">
                <h2 className="text-xl font-semibold mb-4">Tax Consulting</h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    We provide advice based on the applicable tax laws and
                    regulations. Our consultation services are highly flexible
                    and tailored to our clients' needs. Consultations can be
                    conducted on various matters such as tax planning,
                    independent opinions on transactions, application for tax
                    facilities, corporate restructuring, tax audits, tax
                    compliance, and other tax-related issues faced by the
                    company. We prepare advice both verbally and in writing,
                    offering insights and interpretations of the relevant tax
                    regulations.
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsService01(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Tablet>

          <Mobile>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <MobileContainer>
                <div className="bg-white rounded-lg p-6">
                  <h2 className="text-xl font-semibold mb-4">Tax Consulting</h2>
                  <div className="mb-4">
                    <p className=" indent-8 text-justify">
                      We provide advice based on the applicable tax laws and
                      regulations. Our consultation services are highly flexible
                      and tailored to our clients' needs. Consultations can be
                      conducted on various matters such as tax planning,
                      independent opinions on transactions, application for tax
                      facilities, corporate restructuring, tax audits, tax
                      compliance, and other tax-related issues faced by the
                      company. We prepare advice both verbally and in writing,
                      offering insights and interpretations of the relevant tax
                      regulations.
                    </p>
                  </div>
                  <button
                    className=" bg-red-600 text-white px-4 py-2 rounded"
                    onClick={() => setIsService01(false)}
                  >
                    Close
                  </button>
                </div>
              </MobileContainer>
            </div>
          </Mobile>
        </>
      )}

      {service02 && (
        <>
          <LaptopOrDesktop>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[600px]">
                <h2 className="text-xl font-semibold mb-4">
                  Tax Planning and Preparation
                </h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    During our review of your latest tax return and current
                    financial statements, we will discuss your goals, plans, and
                    objectives to determine how to combine strategies to achieve
                    the best results. Our tax planning services include a review
                    of current laws, as well as consideration of proposed
                    regulations and areas of potential tax losses. Our team of
                    trained professionals is committed to maintaining and
                    optimizing our clients' financial positions. The preparation
                    of tax returns is not the final step in our service; it is
                    also the first step for planning the next year.
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsService02(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </LaptopOrDesktop>

          <Tablet>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[600px]">
                <h2 className="text-xl font-semibold mb-4">
                  Tax Planning and Preparation
                </h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    During our review of your latest tax return and current
                    financial statements, we will discuss your goals, plans, and
                    objectives to determine how to combine strategies to achieve
                    the best results. Our tax planning services include a review
                    of current laws, as well as consideration of proposed
                    regulations and areas of potential tax losses. Our team of
                    trained professionals is committed to maintaining and
                    optimizing our clients' financial positions. The preparation
                    of tax returns is not the final step in our service; it is
                    also the first step for planning the next year.
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsService02(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Tablet>

          <Mobile>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <MobileContainer>
                <div className="bg-white rounded-lg p-6">
                  <h2 className="text-xl font-semibold mb-4">
                    Tax Planning and Preparation
                  </h2>
                  <div className="mb-4">
                    <p className=" indent-8 text-justify">
                      During our review of your latest tax return and current
                      financial statements, we will discuss your goals, plans,
                      and objectives to determine how to combine strategies to
                      achieve the best results. Our tax planning services
                      include a review of current laws, as well as consideration
                      of proposed regulations and areas of potential tax losses.
                      Our team of trained professionals is committed to
                      maintaining and optimizing our clients' financial
                      positions. The preparation of tax returns is not the final
                      step in our service; it is also the first step for
                      planning the next year.
                    </p>
                  </div>
                  <button
                    className=" bg-red-600 text-white px-4 py-2 rounded"
                    onClick={() => setIsService02(false)}
                  >
                    Close
                  </button>
                </div>
              </MobileContainer>
            </div>
          </Mobile>
        </>
      )}

      {service03 && (
        <>
          <LaptopOrDesktop>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[600px]">
                <h2 className="text-xl font-semibold mb-4">Tax Compliance</h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    We understand that navigating the complexities of tax
                    regulations can be challenging. Our Tax Compliance services
                    are designed to ensure that your business meets all tax
                    obligations accurately and efficiently. We provide
                    comprehensive support, including:
                  </p>
                  <li className=" ml-6">
                    Accurate Tax Filing: We prepare and file all necessary tax
                    returns on time, minimizing the risk of penalties and
                    ensuring compliance with the latest regulations
                  </li>
                  <li className=" ml-6">
                    Timely Tax Payments: Our team assists in calculating and
                    remitting the correct taxes, including income tax, VAT,
                    withholding tax, and more.
                  </li>
                  <li className=" ml-6">
                    Comprehensive Record-Keeping: We help you maintain detailed
                    financial records and documentation, preparing you for
                    potential audits and reviews by tax authorities.
                  </li>
                  <li className=" ml-6">
                    Regulatory Updates: Our professionals stay up-to-date with
                    changes in tax laws and regulations, providing you with
                    proactive advice to keep your business in compliance.
                  </li>
                  <p className=" indent-8 text-justify">
                    With RA Consulting, you can focus on growing your business
                    while we handle your tax compliance needs with precision and
                    care
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsService03(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </LaptopOrDesktop>

          <Tablet>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[600px]">
                <h2 className="text-xl font-semibold mb-4">Tax Compliance</h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    We understand that navigating the complexities of tax
                    regulations can be challenging. Our Tax Compliance services
                    are designed to ensure that your business meets all tax
                    obligations accurately and efficiently. We provide
                    comprehensive support, including:
                  </p>
                  <li className=" ml-6">
                    Accurate Tax Filing: We prepare and file all necessary tax
                    returns on time, minimizing the risk of penalties and
                    ensuring compliance with the latest regulations
                  </li>
                  <li className=" ml-6">
                    Timely Tax Payments: Our team assists in calculating and
                    remitting the correct taxes, including income tax, VAT,
                    withholding tax, and more.
                  </li>
                  <li className=" ml-6">
                    Comprehensive Record-Keeping: We help you maintain detailed
                    financial records and documentation, preparing you for
                    potential audits and reviews by tax authorities.
                  </li>
                  <li className=" ml-6">
                    Regulatory Updates: Our professionals stay up-to-date with
                    changes in tax laws and regulations, providing you with
                    proactive advice to keep your business in compliance.
                  </li>
                  <p className=" indent-8 text-justify">
                    With RA Consulting, you can focus on growing your business
                    while we handle your tax compliance needs with precision and
                    care
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsService03(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Tablet>

          <Mobile>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <MobileContainer>
                <div className="bg-white rounded-lg p-6 h-[800px] max-h-screen overflow-y-auto">
                  <h2 className="text-xl font-semibold mb-4">Tax Compliance</h2>
                  <div className="mb-4">
                    <p className=" indent-8 text-justify">
                      We understand that navigating the complexities of tax
                      regulations can be challenging. Our Tax Compliance
                      services are designed to ensure that your business meets
                      all tax obligations accurately and efficiently. We provide
                      comprehensive support, including:
                    </p>
                    <li className=" ml-6">
                      Accurate Tax Filing: We prepare and file all necessary tax
                      returns on time, minimizing the risk of penalties and
                      ensuring compliance with the latest regulations
                    </li>
                    <li className=" ml-6">
                      Timely Tax Payments: Our team assists in calculating and
                      remitting the correct taxes, including income tax, VAT,
                      withholding tax, and more.
                    </li>
                    <li className=" ml-6">
                      Comprehensive Record-Keeping: We help you maintain
                      detailed financial records and documentation, preparing
                      you for potential audits and reviews by tax authorities.
                    </li>
                    <li className=" ml-6">
                      Regulatory Updates: Our professionals stay up-to-date with
                      changes in tax laws and regulations, providing you with
                      proactive advice to keep your business in compliance.
                    </li>
                    <p className=" indent-8 text-justify">
                      With RA Consulting, you can focus on growing your business
                      while we handle your tax compliance needs with precision
                      and care
                    </p>
                  </div>
                  <button
                    className=" bg-red-600 text-white px-4 py-2 rounded"
                    onClick={() => setIsService03(false)}
                  >
                    Close
                  </button>
                </div>
              </MobileContainer>
            </div>
          </Mobile>
        </>
      )}

      {service04 && (
        <>
          <LaptopOrDesktop>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[700px]">
                <h2 className="text-xl font-semibold mb-4">
                  Tax Diagnostic Review
                </h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    Our Tax Diagnostics Review service at RA Consulting is
                    designed to provide a thorough examination of your company's
                    tax practices. This comprehensive review identifies
                    potential risks, ensures compliance with current tax
                    regulations, and highlights opportunities for tax savings.
                  </p>
                  <p className=" ml-3">
                    Through our Tax Diagnostics Review, we cover areas such as:
                  </p>
                  <li className=" ml-3">
                    Detailed Tax Analysis: We conduct an in-depth review of your
                    previous tax filings, transactions, and records to identify
                    any discrepancies or areas of concern.
                  </li>
                  <li className=" ml-3">
                    Compliance Check: We assess your adherence to tax
                    regulations, including income tax, VAT, withholding taxes,
                    and other applicable requirements, ensuring your business
                    meets all obligations.
                  </li>
                  <li className=" ml-3">
                    Risk Identification: We identify potential risks and
                    exposure areas that could lead to penalties, helping you
                    address them proactively.
                  </li>
                  <li className=" ml-3">
                    Tax Optimization: Our review aims to uncover possible
                    tax-saving opportunities and recommend strategies to
                    optimize your tax position.
                  </li>
                  <p className=" indent-8 text-justify pt-3">
                    By utilizing our Tax Diagnostics Review, you gain a clear
                    understanding of your tax health, empowering you to make
                    informed financial decisions and maintain compliance with
                    confidence.
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsService04(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </LaptopOrDesktop>

          <Tablet>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[700px]">
                <h2 className="text-xl font-semibold mb-4">
                  Tax Diagnostic Review
                </h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    Our Tax Diagnostics Review service at RA Consulting is
                    designed to provide a thorough examination of your company's
                    tax practices. This comprehensive review identifies
                    potential risks, ensures compliance with current tax
                    regulations, and highlights opportunities for tax savings.
                  </p>
                  <p className=" ml-3">
                    Through our Tax Diagnostics Review, we cover areas such as:
                  </p>
                  <li className=" ml-3">
                    Detailed Tax Analysis: We conduct an in-depth review of your
                    previous tax filings, transactions, and records to identify
                    any discrepancies or areas of concern.
                  </li>
                  <li className=" ml-3">
                    Compliance Check: We assess your adherence to tax
                    regulations, including income tax, VAT, withholding taxes,
                    and other applicable requirements, ensuring your business
                    meets all obligations.
                  </li>
                  <li className=" ml-3">
                    Risk Identification: We identify potential risks and
                    exposure areas that could lead to penalties, helping you
                    address them proactively.
                  </li>
                  <li className=" ml-3">
                    Tax Optimization: Our review aims to uncover possible
                    tax-saving opportunities and recommend strategies to
                    optimize your tax position.
                  </li>
                  <p className=" indent-8 text-justify pt-3">
                    By utilizing our Tax Diagnostics Review, you gain a clear
                    understanding of your tax health, empowering you to make
                    informed financial decisions and maintain compliance with
                    confidence.
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsService04(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Tablet>

          <Mobile>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <MobileContainer>
                <div className="bg-white rounded-lg p-6 h-[800px] max-h-screen overflow-y-auto">
                  <h2 className="text-xl font-semibold mb-4">
                    Tax Diagnostic Review
                  </h2>
                  <div className="mb-4">
                    <p className=" indent-8 text-justify">
                      Our Tax Diagnostics Review service at RA Consulting is
                      designed to provide a thorough examination of your
                      company's tax practices. This comprehensive review
                      identifies potential risks, ensures compliance with
                      current tax regulations, and highlights opportunities for
                      tax savings.
                    </p>
                    <p className=" ml-3">
                      Through our Tax Diagnostics Review, we cover areas such
                      as:
                    </p>
                    <li className=" ml-3">
                      Detailed Tax Analysis: We conduct an in-depth review of
                      your previous tax filings, transactions, and records to
                      identify any discrepancies or areas of concern.
                    </li>
                    <li className=" ml-3">
                      Compliance Check: We assess your adherence to tax
                      regulations, including income tax, VAT, withholding taxes,
                      and other applicable requirements, ensuring your business
                      meets all obligations.
                    </li>
                    <li className=" ml-3">
                      Risk Identification: We identify potential risks and
                      exposure areas that could lead to penalties, helping you
                      address them proactively.
                    </li>
                    <li className=" ml-3">
                      Tax Optimization: Our review aims to uncover possible
                      tax-saving opportunities and recommend strategies to
                      optimize your tax position.
                    </li>
                    <p className=" indent-8 text-justify pt-3">
                      By utilizing our Tax Diagnostics Review, you gain a clear
                      understanding of your tax health, empowering you to make
                      informed financial decisions and maintain compliance with
                      confidence.
                    </p>
                  </div>
                  <button
                    className=" bg-red-600 text-white px-4 py-2 rounded"
                    onClick={() => setIsService04(false)}
                  >
                    Close
                  </button>
                </div>
              </MobileContainer>
            </div>
          </Mobile>
        </>
      )}

      {service05 && (
        <>
          <LaptopOrDesktop>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[700px]">
                <h2 className="text-xl font-semibold mb-4">
                  Tax Litigation Services
                </h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    Facing tax disputes can be complex and stressful, but with
                    RA Consulting by your side, you don't have to navigate it
                    alone. Our Tax Litigation Services are tailored to support
                    and represent your business through every stage of tax
                    disputes and legal proceedings. We combine in-depth
                    knowledge of tax law with strategic advocacy to help resolve
                    conflicts effectively.
                  </p>
                  <p className=" ml-3">Our Tax Litigation Services include:</p>
                  <li className=" ml-3">
                    Dispute Resolution: We represent and assist you in handling
                    disputes with tax authorities, including negotiations,
                    settlements, and administrative appeals.
                  </li>
                  <li className=" ml-3">
                    Tax Audit Support: Our experts provide guidance and support
                    throughout tax audits, ensuring accurate responses to
                    inquiries and helping to mitigate any potential issues.
                  </li>
                  <li className=" ml-3">
                    Legal Representation: In cases that escalate to court, our
                    team offers strong legal representation, leveraging our
                    expertise to defend your interests.
                  </li>
                  <li className=" ml-3">
                    Strategy and Planning: We develop comprehensive strategies
                    based on your unique situation to minimize risks and achieve
                    the best possible outcomes.
                  </li>
                  <p className=" indent-8 text-justify mt-3">
                    RA Consulting is committed to defending your rights and
                    securing fair treatment in all tax-related matters. Let us
                    handle the complexities of tax litigation so you can focus
                    on what matters most—growing your business.
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsService05(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </LaptopOrDesktop>

          <Tablet>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[700px]">
                <h2 className="text-xl font-semibold mb-4">
                  Tax Litigation Services
                </h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    Facing tax disputes can be complex and stressful, but with
                    RA Consulting by your side, you don't have to navigate it
                    alone. Our Tax Litigation Services are tailored to support
                    and represent your business through every stage of tax
                    disputes and legal proceedings. We combine in-depth
                    knowledge of tax law with strategic advocacy to help resolve
                    conflicts effectively.
                  </p>
                  <p className=" ml-3">Our Tax Litigation Services include:</p>
                  <li className=" ml-3">
                    Dispute Resolution: We represent and assist you in handling
                    disputes with tax authorities, including negotiations,
                    settlements, and administrative appeals.
                  </li>
                  <li className=" ml-3">
                    Tax Audit Support: Our experts provide guidance and support
                    throughout tax audits, ensuring accurate responses to
                    inquiries and helping to mitigate any potential issues.
                  </li>
                  <li className=" ml-3">
                    Legal Representation: In cases that escalate to court, our
                    team offers strong legal representation, leveraging our
                    expertise to defend your interests.
                  </li>
                  <li className=" ml-3">
                    Strategy and Planning: We develop comprehensive strategies
                    based on your unique situation to minimize risks and achieve
                    the best possible outcomes.
                  </li>
                  <p className=" indent-8 text-justify mt-3">
                    RA Consulting is committed to defending your rights and
                    securing fair treatment in all tax-related matters. Let us
                    handle the complexities of tax litigation so you can focus
                    on what matters most—growing your business.
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsService05(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Tablet>

          <Mobile>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <MobileContainer>
                <div className="bg-white rounded-lg p-6 h-[800px] max-h-screen overflow-y-auto">
                  <h2 className="text-xl font-semibold mb-4">
                    Tax Litigation Services
                  </h2>
                  <div className="mb-4">
                    <p className=" indent-8 text-justify">
                      Facing tax disputes can be complex and stressful, but with
                      RA Consulting by your side, you don't have to navigate it
                      alone. Our Tax Litigation Services are tailored to support
                      and represent your business through every stage of tax
                      disputes and legal proceedings. We combine in-depth
                      knowledge of tax law with strategic advocacy to help
                      resolve conflicts effectively.
                    </p>
                    <p className=" ml-3">
                      Our Tax Litigation Services include:
                    </p>
                    <li className=" ml-3">
                      Dispute Resolution: We represent and assist you in
                      handling disputes with tax authorities, including
                      negotiations, settlements, and administrative appeals.
                    </li>
                    <li className=" ml-3">
                      Tax Audit Support: Our experts provide guidance and
                      support throughout tax audits, ensuring accurate responses
                      to inquiries and helping to mitigate any potential issues.
                    </li>
                    <li className=" ml-3">
                      Legal Representation: In cases that escalate to court, our
                      team offers strong legal representation, leveraging our
                      expertise to defend your interests.
                    </li>
                    <li className=" ml-3">
                      Strategy and Planning: We develop comprehensive strategies
                      based on your unique situation to minimize risks and
                      achieve the best possible outcomes.
                    </li>
                    <p className=" indent-8 text-justify mt-3">
                      RA Consulting is committed to defending your rights and
                      securing fair treatment in all tax-related matters. Let us
                      handle the complexities of tax litigation so you can focus
                      on what matters most—growing your business.
                    </p>
                  </div>
                  <button
                    className=" bg-red-600 text-white px-4 py-2 rounded"
                    onClick={() => setIsService05(false)}
                  >
                    Close
                  </button>
                </div>
              </MobileContainer>
            </div>
          </Mobile>
        </>
      )}

      {service06 && (
        <>
          <LaptopOrDesktop>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[800px]">
                <h2 className="text-xl font-semibold mb-4">
                  Tax Objection and Appeal
                </h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    Navigating tax assessments can sometimes lead to
                    disagreements with tax authorities. RA Consulting offers
                    expert assistance in filing tax objections and appeals,
                    ensuring your rights are protected and your case is
                    presented effectively. Our experienced professionals guide
                    you through each step, from initial review to final
                    resolution, providing comprehensive support tailored to your
                    needs.
                  </p>
                  <p className=" ml-3">
                    Our Tax Objection and Appeal services include:
                  </p>
                  <li className=" ml-3">
                    Assessment Review: We conduct a detailed analysis of the tax
                    assessment to identify discrepancies or areas where the
                    assessment may be unfair or inaccurate.
                  </li>
                  <li className=" ml-3">
                    Filing Objections: Our team prepares and files formal
                    objections with the tax authorities, ensuring all relevant
                    documentation and arguments are meticulously presented.
                  </li>
                  <li className=" ml-3">
                    Appeal Process: If an objection is not resolved in your
                    favor, we provide representation and support in the appeal
                    process, including preparing for hearings and presenting
                    your case before the relevant tax court.
                  </li>
                  <li className=" ml-3">
                    Strategic Advice: We develop strategies to address complex
                    tax issues, giving you the best possible chance of a
                    successful outcome.
                  </li>
                  <p className=" indent-8 text-justify mt-3">
                    With RA Consulting, you gain a trusted partner committed to
                    safeguarding your interests throughout the tax objection and
                    appeal process. Let us handle the complexities while you
                    focus on your business.
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsService06(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </LaptopOrDesktop>

          <Tablet>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-[700px]">
                <h2 className="text-xl font-semibold mb-4">
                  Tax Objection and Appeal
                </h2>
                <div className="mb-4">
                  <p className=" indent-8 text-justify">
                    Navigating tax assessments can sometimes lead to
                    disagreements with tax authorities. RA Consulting offers
                    expert assistance in filing tax objections and appeals,
                    ensuring your rights are protected and your case is
                    presented effectively. Our experienced professionals guide
                    you through each step, from initial review to final
                    resolution, providing comprehensive support tailored to your
                    needs.
                  </p>
                  <p className=" ml-3">
                    Our Tax Objection and Appeal services include:
                  </p>
                  <li className=" ml-3">
                    Assessment Review: We conduct a detailed analysis of the tax
                    assessment to identify discrepancies or areas where the
                    assessment may be unfair or inaccurate.
                  </li>
                  <li className=" ml-3">
                    Filing Objections: Our team prepares and files formal
                    objections with the tax authorities, ensuring all relevant
                    documentation and arguments are meticulously presented.
                  </li>
                  <li className=" ml-3">
                    Appeal Process: If an objection is not resolved in your
                    favor, we provide representation and support in the appeal
                    process, including preparing for hearings and presenting
                    your case before the relevant tax court.
                  </li>
                  <li className=" ml-3">
                    Strategic Advice: We develop strategies to address complex
                    tax issues, giving you the best possible chance of a
                    successful outcome.
                  </li>
                  <p className=" indent-8 text-justify mt-3">
                    With RA Consulting, you gain a trusted partner committed to
                    safeguarding your interests throughout the tax objection and
                    appeal process. Let us handle the complexities while you
                    focus on your business.
                  </p>
                </div>
                <button
                  className=" bg-red-600 text-white px-4 py-2 rounded"
                  onClick={() => setIsService06(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Tablet>

          <Mobile>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <MobileContainer>
                <div className="bg-white rounded-lg p-6 h-[800px] max-h-screen overflow-y-auto">
                  <h2 className="text-xl font-semibold mb-4">
                    Tax Objection and Appeal
                  </h2>
                  <div className="mb-4">
                    <p className="indent-8 text-justify">
                      Navigating tax assessments can sometimes lead to
                      disagreements with tax authorities. RA Consulting offers
                      expert assistance in filing tax objections and appeals,
                      ensuring your rights are protected and your case is
                      presented effectively. Our experienced professionals guide
                      you through each step, from initial review to final
                      resolution, providing comprehensive support tailored to
                      your needs.
                    </p>
                    <p className="ml-3">
                      Our Tax Objection and Appeal services include:
                    </p>
                    <ul className="list-disc list-inside ml-3">
                      <li>
                        Assessment Review: We conduct a detailed analysis of the
                        tax assessment to identify discrepancies or areas where
                        the assessment may be unfair or inaccurate.
                      </li>
                      <li>
                        Filing Objections: Our team prepares and files formal
                        objections with the tax authorities, ensuring all
                        relevant documentation and arguments are meticulously
                        presented.
                      </li>
                      <li>
                        Appeal Process: If an objection is not resolved in your
                        favor, we provide representation and support in the
                        appeal process, including preparing for hearings and
                        presenting your case before the relevant tax court.
                      </li>
                      <li>
                        Strategic Advice: We develop strategies to address
                        complex tax issues, giving you the best possible chance
                        of a successful outcome.
                      </li>
                    </ul>
                    <p className="indent-8 text-justify mt-3">
                      With RA Consulting, you gain a trusted partner committed
                      to safeguarding your interests throughout the tax
                      objection and appeal process. Let us handle the
                      complexities while you focus on your business.
                    </p>
                  </div>
                  <button
                    className="bg-red-600 text-white px-4 py-2 rounded"
                    onClick={() => setIsService06(false)}
                  >
                    Close
                  </button>
                </div>
              </MobileContainer>
            </div>
          </Mobile>
        </>
      )}

      <LaptopOrDesktop>
        <Animation>
          <Container>
            <div>
              <div>
                <h2 className=" font-bold text-[25px] italic">Our Services</h2>
                <div className=" bg-red-600 h-1 w-28 rounded-full"></div>
              </div>
              <div className=" flex gap-10 pt-5">
                <div>
                  <button
                    onClick={toggleService01}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Consulting
                    </h3>
                  </button>
                </div>
                <div>
                  <button
                    onClick={toggleService02}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Planning and Preparation
                    </h3>
                  </button>
                </div>
                <div>
                  <button
                    onClick={toggleService03}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Compliance
                    </h3>
                  </button>
                </div>
              </div>
              <div className=" flex gap-10 pt-5">
                <div>
                  <button
                    onClick={toggleService04}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Diagnostic Review
                    </h3>
                  </button>
                </div>
                <div>
                  <button
                    onClick={toggleService05}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Litigation Services
                    </h3>
                  </button>
                </div>
                <div>
                  <button
                    onClick={toggleService06}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Objection and Appeal
                    </h3>
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </Animation>
      </LaptopOrDesktop>

      <Tablet>
        <Animation>
          <TabletContainer>
            <div>
              <div>
                <h2 className=" font-bold text-[25px] italic">Our Services</h2>
                <div className=" bg-red-600 h-1 w-28 rounded-full"></div>
              </div>
              <div className=" flex justify-center gap-10 pt-5">
                <div>
                  <button
                    onClick={toggleService01}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Consulting
                    </h3>
                  </button>
                </div>
                <div>
                  <button
                    onClick={toggleService03}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Compliance
                    </h3>
                  </button>
                </div>
              </div>
              <div className=" flex justify-center gap-10 pt-5">
                <div>
                  <button
                    onClick={toggleService04}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Diagnostic Review
                    </h3>
                  </button>
                </div>
                <div>
                  <button
                    onClick={toggleService05}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Litigation Services
                    </h3>
                  </button>
                </div>
              </div>
              <div className=" flex justify-center gap-10 pt-5">
                <div>
                  <button
                    onClick={toggleService02}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Planning and Preparation
                    </h3>
                  </button>
                </div>
                <div>
                  <button
                    onClick={toggleService06}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Objection and Appeal
                    </h3>
                  </button>
                </div>
              </div>
            </div>
          </TabletContainer>
        </Animation>
      </Tablet>

      <Mobile>
        <Animation>
          <MobileContainer>
            <div>
              <div>
                <h2 className=" font-bold text-[25px] italic">Our Services</h2>
                <div className=" bg-red-600 h-1 w-28 rounded-full"></div>
              </div>
              <div className="pt-5">
                <div>
                  <button
                    onClick={toggleService01}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Consulting
                    </h3>
                  </button>
                </div>
                <div className=" py-5">
                  <button
                    onClick={toggleService02}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Planning and Preparation
                    </h3>
                  </button>
                </div>
                <div>
                  <button
                    onClick={toggleService03}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Compliance
                    </h3>
                  </button>
                </div>
              </div>
              <div className="pt-5">
                <div>
                  <button
                    onClick={toggleService04}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Diagnostic Review
                    </h3>
                  </button>
                </div>
                <div className=" py-5">
                  <button
                    onClick={toggleService05}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Litigation Services
                    </h3>
                  </button>
                </div>
                <div>
                  <button
                    onClick={toggleService06}
                    className=" border-2 h-12 w-[300px] font-bold border-black rounded-lg"
                  >
                    <h3 className=" text-[15px] text-left pl-2">
                      Tax Objection and Appeal
                    </h3>
                  </button>
                </div>
              </div>
            </div>
          </MobileContainer>
        </Animation>
      </Mobile>
    </>
  );
}
