import Container from "./Container";
import { useMediaQuery } from "react-responsive";
import MobileContainer from "./MobileContainer";
import Animation from "./Animation";
import TabletContainer from "./TabletContainer";

export default function About() {
  const LaptopOrDesktop = ({ children }) => {
    const isLaptopOrDesktop = useMediaQuery({ minWidth: 1080, maxWidth: 1980 });
    return isLaptopOrDesktop ? children : null;
  };

  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 766, maxWidth: 1080 });
    return isTablet ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 766 });
    return isMobile ? children : null;
  };

  return (
    <>
      <LaptopOrDesktop>
        <Animation>
          <Container>
            <div>
              <div>
                <h2 className=" font-bold text-[25px] italic">ABOUT</h2>
                <div className=" bg-red-600 h-1 w-14 rounded-full"></div>
              </div>
              <div className=" flex justify-center mt-4">
                <div className=" w-[1000px] text-justify">
                  <p className=" indent-8">
                    RA Consulting, we specialize in providing top-tier Tax and
                    Accounting consultancy services tailored to meet the diverse
                    needs of our clients. Located at Revenue Tower - District 8
                    SCBD, 26th Floor, Jl. Jend. Sudirman Kav. 52-53, Kebayoran
                    Baru, Jakarta Selatan 12190, our central location enables us
                    to serve clients across Jakarta and beyond.
                  </p>
                  <p className=" indent-8 pt-3">
                    Ready to experience the difference of working with true tax
                    and accounting experts? Reach out to us today at
                    info@rac.co.id to schedule a consultation and discover how
                    RA Consulting can help you achieve your financial goals with
                    confidence.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </Animation>
      </LaptopOrDesktop>

      <Tablet>
        <Animation>
          <TabletContainer>
            <div>
              <div>
                <h2 className=" font-bold text-[25px] italic">ABOUT</h2>
                <div className=" bg-red-600 h-1 w-14 rounded-full"></div>
              </div>
              <div className=" flex justify-center mt-4">
                <div className=" w-[1000px] text-justify">
                  <p className=" indent-8">
                    RA Consulting, we specialize in providing top-tier Tax and
                    Accounting consultancy services tailored to meet the diverse
                    needs of our clients. Located at Revenue Tower - District 8
                    SCBD, 26th Floor, Jl. Jend. Sudirman Kav. 52-53, Kebayoran
                    Baru, Jakarta Selatan 12190, our central location enables us
                    to serve clients across Jakarta and beyond.
                  </p>
                  <p className=" indent-8 pt-3">
                    Ready to experience the difference of working with true tax
                    and accounting experts? Reach out to us today at
                    info@rac.co.id to schedule a consultation and discover how
                    RA Consulting can help you achieve your financial goals with
                    confidence.
                  </p>
                </div>
              </div>
            </div>
          </TabletContainer>
        </Animation>
      </Tablet>

      <Mobile>
        <Animation>
          <MobileContainer>
            <div>
              <div>
                <h2 className=" font-bold text-[25px] italic">ABOUT</h2>
                <div className=" bg-red-600 h-1 w-14 rounded-full"></div>
              </div>
              <div className=" flex justify-center mt-4">
                <div className=" w-[1000px] text-justify">
                  <p className=" indent-8">
                    RA Consulting, we specialize in providing top-tier Tax and
                    Accounting consultancy services tailored to meet the diverse
                    needs of our clients. Located at Revenue Tower - District 8
                    SCBD, 26th Floor, Jl. Jend. Sudirman Kav. 52-53, Kebayoran
                    Baru, Jakarta Selatan 12190, our central location enables us
                    to serve clients across Jakarta and beyond.
                  </p>
                  <p className=" indent-8 pt-3">
                    Ready to experience the difference of working with true tax
                    and accounting experts? Reach out to us today at
                    info@rac.co.id to schedule a consultation and discover how
                    RA Consulting can help you achieve your financial goals with
                    confidence.
                  </p>
                </div>
              </div>
            </div>
          </MobileContainer>
        </Animation>
      </Mobile>
    </>
  );
}
