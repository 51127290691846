import "./App.css";
import Hero from "./components/Hero";
import LogoRAC from "./assets/logo.png";
import Container from "./components/Container";
import {
  IoLogoLinkedin,
  IoLogoInstagram,
  IoLogoWhatsapp,
  IoMailOutline,
  IoList,
  IoLocationSharp
} from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import About from "./components/About";
import OurTeams from "./components/OurTeams";
import { PiCopyrightLight } from "react-icons/pi";
import OurClients from "./components/OurClients";
import OurServices from "./components/OurServices";
import { useRef, useState } from "react";
import MobileContainer from "./components/MobileContainer";
import TabletContainer from "./components/TabletContainer";
import Animation from "./components/Animation";

function App() {
  const ourTeams = useRef(null);
  const ourServices = useRef(null);
  const ourClients = useRef(null);
  const home = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickToInstagram = () => {
    window.open("https://www.instagram.com/raconsulting_official/");
  };

  const handleToClickLinkedin = () => {
    window.open("https://id.linkedin.com/company/rahmadadigunakonsulting");
  };

  const handleToClickContactUs = () => {
    window.open("mailto:info@rac.co.id");
  };

  const handleToClickWhatsapp = () => {
    window.open("https://wa.me/6285888388439", "_blank", "noopener,noreferrer");
  };

  const handleToClickMaps = () => {
    window.open(
      "https://maps.google.com?q=Kantor%20RA%20Consulting,%20WeWork%20Revenue%20Tower,%2026th%20Floor,%2026-103,%20Kec.%20Kby.%20Baru,%20Kota%20Jakarta%20Selatan,%20Daerah%20Khusus%20Ibukota%20Jakarta%2012190&ftid=0x0:0x36d93130e686896e&entry=gps&lucs=,94242508,94224825,94227247,94227248,47071704,47069508,94218641,94203019,47084304,94208458,94208447&g_st=com.google.maps.preview.copy"
    );
  };

  const handleClickToHome = () => {
    home.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickToOurTeams = () => {
    ourTeams.current?.scrollIntoView({ behavior: "smooth" });
    setIsOpen(false);
  };
  const handleClickToOurServices = () => {
    ourServices.current?.scrollIntoView({ behavior: "smooth" });
    setIsOpen(false);
  };
  const handleClickToOurClients = () => {
    ourClients.current?.scrollIntoView({ behavior: "smooth" });
    setIsOpen(false);
  };

  const LaptopOrDesktop = ({ children }) => {
    const isLaptopOrDesktop = useMediaQuery({ minWidth: 1080, maxWidth: 1980 });
    return isLaptopOrDesktop ? children : null;
  };

  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 766, maxWidth: 1080 });
    return isTablet ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 766 });
    return isMobile ? children : null;
  };

  return (
    <>
      <LaptopOrDesktop>
        <Container>
          <button
            onClick={handleToClickWhatsapp}
            className="fixed bottom-4 right-4 bg-green-500 text-white p-2 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
          >
            <IoLogoWhatsapp size={30} />
          </button>
        </Container>

        <nav className=" bg-white w-full fixed z-10 py-3 rounded-b-xl">
          <Container>
            <div className=" flex justify-between items-center">
              <div>
                <button onClick={handleClickToHome}>
                  <img className=" w-[170px]" src={LogoRAC} alt="navbar-logo" />
                </button>
              </div>
              <div className=" font-semibold text-lg">
                <button onClick={handleClickToOurTeams}>Our Teams</button>
                <button onClick={handleClickToOurServices} className=" px-7">
                  Our Services
                </button>
                <button onClick={handleClickToOurClients}>Our Clients</button>
              </div>
              <div>
                <button
                  onClick={handleToClickContactUs}
                  className=" bg-red-600 py-2 px-5 rounded-md text-sm font-bold text-white"
                >
                  CONTACT US
                </button>
              </div>
            </div>
          </Container>
        </nav>

        <section ref={home} className=" pt-14">
          <Hero />
        </section>

        <section className=" pt-10">
          <About />
        </section>

        <section className=" mt-20">
          <Animation>
            <Container>
              <div className=" flex justify-between w-full">
                <div className=" bg-white shadow-xl rounded-r-lg-lg w-[320px] flex items-center">
                  <div className=" bg-red-600 rounded-lg w-1 h-28 mr-5"></div>
                  <div>
                    <h4 className=" font-bold text-xl">Motto</h4>
                    <p className=" italic">Tax Disputes, we are the experts</p>
                  </div>
                </div>
                <div className=" bg-white shadow-xl rounded-r-lg-lg w-[360px] flex items-center">
                  <div className=" bg-red-600 rounded-lg w-1 h-28 mr-5"></div>
                  <div>
                    <h4 className=" font-bold text-xl">Vission</h4>
                    <p className=" w-[316px] italic">
                      We envision ourselves becoming one of the leading tax
                      consulting firms in Indonesia.
                    </p>
                  </div>
                </div>
                <div className=" bg-white shadow-xl rounded-r-lg-lg w-[320px] flex items-center">
                  <div className=" bg-red-600 rounded-lg w-1 h-28 mr-5"></div>
                  <div>
                    <h4 className=" font-bold text-xl">Mission</h4>
                    <p className=" italic">
                      To deliver the best service to clients.
                    </p>
                  </div>
                </div>
              </div>
            </Container>
          </Animation>
        </section>

        <section ref={ourTeams} className=" pt-[80px]">
          <OurTeams />
        </section>

        <section ref={ourServices} className=" pt-[80px]">
          <OurServices />
        </section>

        <section ref={ourClients} className=" pt-[80px]">
          <OurClients />
        </section>

        <footer className=" mt-16">
          <div className=" bg-red-600 w-full py-10 rounded-t-xl">
            <Container>
              <div className=" text-center text-white">
                <div className=" flex justify-between">
                  <h3 className=" font-bold text-lg">Phone</h3>
                  <h3 className=" font-bold text-lg">Address</h3>
                  <h3 className=" font-bold text-lg">Email</h3>
                </div>
                <div className=" flex w-full justify-between">
                  <p>+62 858-8838-8439</p>
                  <p className=" w-[650px]">
                    WeWork 26th Floor Office 26-103 Revenue Tower - District 8
                    SCBD Jl. Jendral Sudirman Kav 52-53 Kebayoran Baru - Jakarta
                    Selatan 12190
                  </p>
                  <p>info@rac.co.id</p>
                </div>
                <div className=" flex justify-center py-10 gap-2">
                  <button onClick={handleToClickLinkedin}>
                    <IoLogoLinkedin size={30} />
                  </button>
                  <button onClick={handleClickToInstagram}>
                    <IoLogoInstagram size={30} />
                  </button>
                  <button onClick={handleToClickMaps}>
                    <IoLocationSharp size={30}/>
                  </button>
                </div>
                <div className=" flex justify-center items-center gap-1">
                  <PiCopyrightLight size={15} />
                  <p className=" text-xs italic">
                    2024 All rights reserved by RA Consulting
                  </p>
                </div>
              </div>
            </Container>
          </div>
        </footer>
      </LaptopOrDesktop>

      <Tablet>
        <TabletContainer>
          <button
            onClick={handleToClickWhatsapp}
            className=" z-20 fixed bottom-4 right-4 bg-green-500 text-white p-2 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
          >
            <IoLogoWhatsapp size={30} />
          </button>
        </TabletContainer>

        <nav className=" bg-white w-full fixed z-10 py-3 rounded-b-xl">
          <TabletContainer>
            <div className=" flex justify-between items-center">
              <div>
                <button onClick={handleClickToHome}>
                  <img className=" w-[170px]" src={LogoRAC} alt="navbar-logo" />
                </button>
              </div>
              <div className=" font-semibold text-lg">
                <button onClick={handleClickToOurTeams}>Our Teams</button>
                <button onClick={handleClickToOurServices} className=" px-7">
                  Our Services
                </button>
                <button onClick={handleClickToOurClients}>Our Clients</button>
              </div>
              <div>
                <button
                  onClick={handleToClickContactUs}
                  className=" bg-red-600 py-2 px-5 rounded-md text-sm font-bold text-white"
                >
                  CONTACT US
                </button>
              </div>
            </div>
          </TabletContainer>
        </nav>

        <section ref={home} className=" pt-14">
          <Hero />
        </section>

        <section className=" pt-10">
          <About />
        </section>

        <section className=" mt-20">
          <Animation>
            <TabletContainer>
              <div className=" flex justify-center gap-4 w-full text-center">
                <div className=" bg-white shadow-xl rounded-r-lg-lg w-[270px] flex items-center">
                  <div className=" bg-red-600 rounded-lg w-1 h-28 mr-5"></div>
                  <div>
                    <h4 className=" font-bold text-xl">Motto</h4>
                    <p className=" italic">Tax Disputes, we are the experts</p>
                  </div>
                  <div className=" bg-red-600 rounded-lg w-1 h-28 ml-5"></div>
                </div>
                <div className=" bg-white shadow-xl rounded-r-lg-lg w-[290px] flex items-center">
                  <div className=" bg-red-600 rounded-lg w-1 h-28 mr-4"></div>
                  <div>
                    <h4 className=" font-bold text-xl">Mission</h4>
                    <p className=" italic">
                      To deliver the best service to clients.
                    </p>
                  </div>
                  <div className=" bg-red-600 rounded-lg w-1 h-28 ml-4"></div>
                </div>
              </div>

              <div className=" w-full flex justify-center text-center mt-4">
                <div className=" bg-white shadow-xl rounded-r-lg-lg w-[350px] flex items-center">
                  <div className=" bg-red-600 rounded-lg w-1 h-28 mr-5"></div>
                  <div>
                    <h4 className=" font-bold text-xl">Vission</h4>
                    <p className=" w-[300px] italic">
                      We envision ourselves becoming one of the leading tax
                      consulting firms in Indonesia.
                    </p>
                  </div>
                  <div className=" bg-red-600 rounded-lg w-1 h-28 ml-5"></div>
                </div>
              </div>
            </TabletContainer>
          </Animation>
        </section>

        <section ref={ourTeams} className=" pt-[80px]">
          <OurTeams />
        </section>

        <section ref={ourServices} className=" pt-[80px]">
          <OurServices />
        </section>

        <section ref={ourClients} className=" pt-[80px]">
          <OurClients />
        </section>

        <footer className=" mt-16">
          <div className=" bg-red-600 w-full py-10 rounded-t-xl">
            <Container>
              <div className=" text-center text-white">
                <div className=" flex justify-between">
                  <h3 className=" font-bold text-lg">Phone</h3>
                  <h3 className=" font-bold text-lg">Address</h3>
                  <h3 className=" font-bold text-lg">Email</h3>
                </div>
                <div className=" flex w-full justify-between">
                  <p>+62 858-8838-8439</p>
                  <p className=" w-[400px]">
                    WeWork 26th Floor Office 26-103 Revenue Tower - District 8
                    SCBD Jl. Jendral Sudirman Kav 52-53 Kebayoran Baru - Jakarta
                    Selatan 12190
                  </p>
                  <p>info@rac.co.id</p>
                </div>
                <div className=" flex justify-center py-6 gap-2">
                  <button onClick={handleToClickLinkedin}>
                    <IoLogoLinkedin size={30} />
                  </button>
                  <button onClick={handleClickToInstagram}>
                    <IoLogoInstagram size={30} />
                  </button>
                  <button onClick={handleToClickMaps}>
                    <IoLocationSharp size={30}/>
                  </button>
                </div>
                <div className=" flex justify-center items-center gap-1">
                  <PiCopyrightLight size={15} />
                  <p className=" text-xs italic">
                    2024 All rights reserved by RA Consulting
                  </p>
                </div>
              </div>
            </Container>
          </div>
        </footer>
      </Tablet>

      <Mobile>
        <MobileContainer>
          <button
            onClick={handleToClickWhatsapp}
            className="fixed bottom-4 right-4 bg-green-500 text-white p-2 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
          >
            <IoLogoWhatsapp size={30} />
          </button>
        </MobileContainer>

        <nav className=" bg-white w-full fixed z-10 py-3 rounded-b-xl">
          <MobileContainer>
            <div className=" flex justify-between items-center">
              <div>
                <button onClick={handleClickToHome}>
                  <img className=" w-[130px]" src={LogoRAC} alt="navbar-logo" />
                </button>
              </div>
              <div className=" flex items-center gap-3">
                <div className="relative inline-block text-left">
                  <button
                    onClick={toggleDropdown}
                    className=" flex items-center"
                  >
                    <IoList size={30} />
                  </button>

                  {isOpen && (
                    <div className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-red-600 ring-1 ring-black ring-opacity-5">
                      <div className="">
                        <button
                          onClick={handleClickToOurTeams}
                          className=" w-[100px] px-2 py-2 font-semibold text-sm text-white active:bg-red-400"
                        >
                          Our Teams
                        </button>
                        <button
                          onClick={handleClickToOurServices}
                          className=" w-[100px] px-2 py-2 font-semibold text-sm text-white active:bg-red-400"
                        >
                          Our Services
                        </button>
                        <button
                          onClick={handleClickToOurClients}
                          className=" w-[100px] px-2 py-2 font-semibold text-sm text-white active:bg-red-400"
                        >
                          Our Clients
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  onClick={handleToClickContactUs}
                  className=" bg-red-600 p-1 rounded-md text-sm font-bold text-white"
                >
                  <IoMailOutline size={25} />
                </button>
              </div>
            </div>
          </MobileContainer>
        </nav>

        <section ref={home} className=" pt-12">
          <Hero />
        </section>

        <section className=" pt-[40px]">
          <About />
        </section>

        <section className=" pt-[62px]">
          <Animation>
            <MobileContainer>
              <div className=" flex w-full justify-center">
                <div>
                  <div className=" bg-white shadow-xl rounded-b-md w-[320px] flex justify-center items-center">
                    <div>
                      <div className=" bg-red-600 h-1 w-[320px] rounded-t-md"></div>
                      <div className=" py-4 text-center">
                        <h4 className=" font-bold text-xl">Motto</h4>
                        <p className=" italic">
                          Tax Disputes, we are the experts
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" bg-white shadow-xl rounded-b-md w-[320px] my-6 flex justify-center items-center">
                    <div>
                      <div className=" bg-red-600 h-1 w-[320px] rounded-t-md"></div>
                      <div className=" py-4 text-center">
                        <h4 className=" font-bold text-xl">Vission</h4>
                        <p className=" w-[316px] italic">
                          We envision ourselves becoming one of the leading tax
                          consulting firms in Indonesia.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" bg-white shadow-xl rounded-b-md w-[320px] flex justify-center items-center">
                    <div>
                      <div className=" bg-red-600 h-1 w-[320px] rounded-t-md"></div>
                      <div className=" py-4 text-center">
                        <h4 className=" font-bold text-xl">Mission</h4>
                        <p className=" italic">
                          To deliver the best service to clients.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MobileContainer>
          </Animation>
        </section>

        <section ref={ourTeams} className=" pt-[56px]">
          <OurTeams />
        </section>

        <section ref={ourServices} className=" pt-[56px]">
          <OurServices />
        </section>

        <section ref={ourClients} className=" pt-[56px]">
          <OurClients />
        </section>

        <footer className=" mt-16">
          <div className=" bg-red-600 w-full py-10 rounded-t-xl">
            <MobileContainer>
              <div className=" text-center text-white">
                <div>
                  <h3 className=" font-bold text-lg">Phone</h3>
                  <p>+62 858-8838-8439</p>
                </div>
                <div className=" py-5">
                  <h3 className=" font-bold text-lg">Address</h3>
                  <p className="">
                    WeWork 26th Floor Office 26-103 Revenue Tower - District 8
                    SCBD Jl. Jendral Sudirman Kav 52-53 Kebayoran Baru - Jakarta
                    Selatan 12190
                  </p>
                </div>
                <div>
                  <h3 className=" font-bold text-lg">Email</h3>
                  <p>info@rac.co.id</p>
                </div>
                <div className=" flex justify-center py-10 gap-2">
                  <button onClick={handleToClickLinkedin}>
                    <IoLogoLinkedin size={30} />
                  </button>
                  <button onClick={handleClickToInstagram}>
                    <IoLogoInstagram size={30} />
                  </button>
                  <button onClick={handleToClickMaps}>
                    <IoLocationSharp size={30}/>
                  </button>
                </div>
                <div className=" flex justify-center items-center gap-1">
                  <PiCopyrightLight size={15} />
                  <p className=" text-xs italic">
                    2024 All rights reserved by RA Consulting
                  </p>
                </div>
              </div>
            </MobileContainer>
          </div>
        </footer>
      </Mobile>
    </>
  );
}

export default App;
